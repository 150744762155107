import React from "react"
import styles from "./style.module.scss"
import cx from "classnames"
import { CARDS, cardColours } from "../../pages/play"

const Card = ({
  index,
  card,
  disabled,
  onClick,
  handleLetterSelection,
  word,
  isNominated,
  placementMode,
  handlePlaceCard,
  isPlayArea,
}) => {
  const width = 150
  const height = 222
  const isSingleLetter = Object.keys(card).length === 2
  const disabledCard = disabled
  return (
    <svg
      style={{
        opacity: !disabledCard ? 1 : 0.5,
      }}
      width="100%"
      height="auto"
      // viewBox="0 0 137 137"
      viewBox={`0 0 ${width} ${height}`}
      // width="100%"
      // height="100%"
      className={cx({
        [`${styles.cardSvgInteractive}`]: !disabledCard,
        [`${styles.cardSvg}`]: disabledCard,
        [`${styles.selected}`]: isNominated,
        [`${styles.cardFloatable}`]: isPlayArea,
      })}
      onClick={onClick}
    >
      <rect
        className={cx(styles.cardBg, {
          [`${styles.cardPlacementMode}`]: placementMode,
          [`${styles.cardPlaceable}`]: placementMode,
        })}
        rx="10px"
        ry="10px"
        x="0"
        y="0"
        width={width}
        height={height}
        onClick={placementMode ? () => handlePlaceCard(index) : null}
      ></rect>
      <g className={cx(styles.cardNumbers)}>
        <text
          width={50}
          height={50}
          textAnchor="middle"
          dominantBaseline="central"
          x={20}
          y={20}
        >
          {card.cardNo}
        </text>
        <text
          width={50}
          height={50}
          textAnchor="middle"
          dominantBaseline="central"
          x={width - 20}
          y={height - 20}
        >
          {card.cardNo}
        </text>
      </g>
      {Object.keys(card).map((letterIndex, i) => {
        const c = CARDS.find(_card =>
          Object.keys(card).find(k => card[k] === _card[letterIndex])
        )
        let cardNumber = c ? Object.keys(c)[0] : null
        if (!isNaN(letterIndex)) {
          const isLetterSelected = word.find(
            l => l.index === letterIndex && l.letter === ""
          )
          const _letterPosition =
            i === 0 || card[letterIndex] === "a" || card[letterIndex] === "z"
              ? 1
              : 3
          const _letterPosition2 =
            i === 1 || card[letterIndex] === "a" || card[letterIndex] === "z"
              ? 2
              : 4

          if (i === 0) {
            return (
              <React.Fragment key={i}>
                <text
                  width={50}
                  height={50}
                  className={cx(
                    `fill-${cardColours[+letterIndex]}`,
                    styles.cardText
                  )}
                  textAnchor="middle"
                  dominantBaseline="central"
                  x={isSingleLetter ? width / 2 : width / 4}
                  y={i === 0 ? height / 4 : height - height / 4}
                  onClick={
                    !disabledCard && !placementMode
                      ? () =>
                          handleLetterSelection({
                            index: card.index,
                            letter: `${card[letterIndex]}_${_letterPosition}`,
                          })
                      : null
                  }
                >
                  {card[letterIndex]?.toUpperCase()}
                  {word.find(
                    l =>
                      l.index === card.index &&
                      l.letter === `${card[letterIndex]}_${_letterPosition}`
                  )
                    ? "*"
                    : null}
                </text>
                <text
                  width={50}
                  height={50}
                  className={cx(
                    `fill-${cardColours[+letterIndex]}`,
                    styles.cardText
                  )}
                  textAnchor="middle"
                  dominantBaseline="central"
                  x={isSingleLetter ? width / 2 : width - width / 4}
                  y={i === 1 ? height / 4 : height - height / 4}
                  onClick={
                    !disabledCard && !placementMode
                      ? () =>
                          handleLetterSelection({
                            index: card.index,
                            letter: `${card[letterIndex]}_${_letterPosition2}`,
                          })
                      : null
                  }
                >
                  {card[letterIndex]?.toUpperCase()}
                  {word.find(
                    l =>
                      l.index === card.index &&
                      l.letter === `${card[letterIndex]}_${_letterPosition2}`
                  )
                    ? "*"
                    : null}
                </text>
              </React.Fragment>
            )
          }
        }
      })}
      {Object.keys(card).map((letterIndex, i) => {
        const c = CARDS.find(_card =>
          Object.keys(card).find(k => card[k] === _card[letterIndex])
        )
        let cardNumber = c ? Object.keys(c)[0] : null
        if (!isNaN(letterIndex)) {
          const isLetterSelected = word.find(
            l => l.index === letterIndex && l.letter === ""
          )
          const _letterPosition =
            i === 0 || card[letterIndex] === "a" || card[letterIndex] === "z"
              ? 1
              : 3
          const _letterPosition2 =
            i === 1 || card[letterIndex] === "a" || card[letterIndex] === "z"
              ? 2
              : 4

          if (i === 1) {
            return (
              <React.Fragment key={i}>
                <text
                  width={50}
                  height={50}
                  className={cx(
                    `fill-${cardColours[+letterIndex]}`,
                    styles.cardText
                  )}
                  textAnchor="middle"
                  dominantBaseline="central"
                  x={isSingleLetter ? width / 2 : width / 4}
                  y={i === 0 ? height / 4 : height - height / 4}
                  onClick={
                    !disabledCard && !placementMode
                      ? () =>
                          handleLetterSelection({
                            index: card.index,
                            letter: `${card[letterIndex]}_${_letterPosition}`,
                          })
                      : null
                  }
                >
                  {card[letterIndex]?.toUpperCase()}
                  {word.find(
                    l =>
                      l.index === card.index &&
                      l.letter === `${card[letterIndex]}_${_letterPosition}`
                  )
                    ? "*"
                    : null}
                </text>
                <text
                  width={50}
                  height={50}
                  className={cx(
                    `fill-${cardColours[+letterIndex]}`,
                    styles.cardText
                  )}
                  textAnchor="middle"
                  dominantBaseline="central"
                  x={isSingleLetter ? width / 2 : width - width / 4}
                  y={i === 1 ? height / 4 : height - height / 4}
                  onClick={
                    !disabledCard && !placementMode
                      ? () =>
                          handleLetterSelection({
                            index: card.index,
                            letter: `${card[letterIndex]}_${_letterPosition2}`,
                          })
                      : null
                  }
                >
                  {card[letterIndex]?.toUpperCase()}
                  {word.find(
                    l =>
                      l.index === card.index &&
                      l.letter === `${card[letterIndex]}_${_letterPosition2}`
                  )
                    ? "*"
                    : null}
                </text>
              </React.Fragment>
            )
          }
        }
      })}
    </svg>
  )
}

export default Card
