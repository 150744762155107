import { faChevronLeft, faChevronRight, faClock, faUsers } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"
import dayjs from "dayjs"
import React, { memo } from "react"
import { Badge, Button, ListGroup, OverlayTrigger, Popover } from "react-bootstrap"

import { loadPlayerId } from "../../utils"

const userScoreColours = {
  0: "primary",
  1: "info",
  2: "warning",
  3: "success",
  4: "danger",
  5: "primary",
  6: "info",
  7: "warning",
  8: "success",
  9: "danger",
  10: "primary",
  11: "info",
  12: "warning",
  13: "success",
  14: "danger",
  15: "primary",
  16: "info",
  17: "warning",
  18: "success",
  19: "danger",
  20: "primary",
}

function groupScoresByLevel(sortedScores) {
  const groupedScores = {}

  for (const score of sortedScores) {
    const level = score.level

    if (!groupedScores[level]) {
      groupedScores[level] = []
    }

    groupedScores[level].push(score)
  }

  return groupedScores
}

const Scoreboard = ({ scores, hideTitle, toggleScoreboard, collapsed }) => {
  const currentPlayerId = loadPlayerId()
  if (!scores || !Object.keys(scores).length) {
    return null
  }

  const formatted = Object.keys(scores).map(key => {
    const xdate1 = dayjs(
      `2021-01-01 ${scores[key].end.split("T")[1].split("+")[0]}`
    )
    const xdate2 = dayjs(
      `2021-01-01 ${scores[key].start.split("T")[1].split("+")[0]}`
    )
    const playTime = xdate1.diff(xdate2, "seconds")
    return {
      playerId: key.split("-")[2],
      player: scores[key].player,
      level: scores[key].level,
      playTime,
      time: scores[key].time,
      proMode: scores[key].proMode,
      incomplete: scores[key].incomplete,
    }
  })

  let sortedScores = formatted
    .sort(function (xKey, yKey) {
      if (xKey.level === yKey.level) {
        // If levels are the same, sort by playTime
        if (xKey.incomplete && !yKey.incomplete) {
          return 1; // xKey should come after yKey because it's incomplete
        } else if (!xKey.incomplete && yKey.incomplete) {
          return -1; // xKey should come before yKey because yKey is incomplete
        } else {
          return xKey.playTime - yKey.playTime; // Sort by playTime
        }
      } else {
        // Sort by level
        return xKey.level < yKey.level ? 1 : -1;
      }
    })
    .filter(s => s.proMode);


  sortedScores = groupScoresByLevel(sortedScores)

  function secondsToHms(d) {
    d = Number(d)
    var h = Math.floor(d / 3600)
    var m = Math.floor((d % 3600) / 60)
    var s = Math.floor((d % 3600) % 60)

    var hDisplay = h > 0 ? h + (h == 1 ? ":" : ":") : ""
    var mDisplay = m > 0 ? m + (m == 1 ? ":" : ":") : ""
    var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : ""
    return hDisplay + mDisplay + sDisplay
  }

  function convertSeconds(seconds) {
    var convert = function (x) {
      return x < 10 ? "0" + x : x
    }
    return (
      convert(parseInt(seconds / (60 * 60))) +
      ":" +
      convert(parseInt((seconds / 60) % 60)) +
      ":" +
      convert(seconds % 60)
    )
  }

  console.log({ sortedScores })

  const renderScores = Object.keys(sortedScores)?.map((levelKey, i) => (
    <React.Fragment key={i}>
      <strong
        className={cx("my-3 text-danger bold", {
          'h5 text-left ml-3': !collapsed,
          'h6 text-center': collapsed,
        })}
      >
        LEVEL {levelKey}
      </strong>
      {sortedScores[levelKey].map((score, si) => {
        return (
          <>
            <OverlayTrigger
              key={si}
              trigger={!collapsed ? "none" : "hover"}
              placement="left"
              overlay={
                <Popover id="popover-basic">
                  {/* <Popover.Title as="h3" className={cx(`bg-${userScoreColours[i]}`)}>
              {score.player}
              {currentPlayerId === score.playerId ? " (You)" : null}
            </Popover.Title> */}
                  <Popover.Content>
                    <div
                      className={cx(
                        // "d-flex justify-content-between align-items-start",
                        "font-weight-bold",
                        `text-${userScoreColours[i]}`
                      )}
                    >
                      {score.player}
                      {currentPlayerId === score.playerId ? " (You)" : null}
                    </div>
                    <h6 className="m-0 text-muted">
                      <FontAwesomeIcon icon={faClock} className="" />{" "}
                      <small>{convertSeconds(score.playTime)}</small>
                    </h6>
                    <div>
                      {/* {score.proMode && (
                        <span className="badge badge-warning mr-2">PRO</span>
                      )} */}
                      {score?.incomplete && (
                        <span className="badge badge-warning mr-2">Incomplete</span>
                      )}
                      <span className="badge badge-info">
                        Lvl {score.level}
                      </span>
                    </div>
                  </Popover.Content>
                </Popover>
              }
            >
              <ListGroup.Item
                key={i}
                as="li"
                className={cx("d-flex align-items-start cursor-pointer", {
                  "justify-content-between": !collapsed,
                  "justify-content-center": collapsed,
                  "bg-secondary text-white": currentPlayerId === score.playerId,
                  "text-muted": currentPlayerId !== score.playerId,
                })}
              >
                <div>
                  <div
                    className={cx("font-weight-bold", {
                      [`text-${userScoreColours[i]}`]:
                        currentPlayerId !== score.playerId,
                      "text-white": currentPlayerId === score.playerId,
                      "pb-2": collapsed && currentPlayerId === score.playerId,
                    })}
                  >
                    {collapsed && currentPlayerId === score.playerId && (
                      <small
                        style={{
                          position: "absolute",
                          bottom: "5px",
                          left: "25px",
                        }}
                      >
                        (You)
                      </small>
                    )}
                    {si + 1}
                  </div>
                </div>
                <div className={cx("mr-auto ml-3", { "d-none": collapsed })}>
                  <div
                    className={cx("font-weight-bold", {
                      [`text-${userScoreColours[i]}`]:
                        currentPlayerId !== score.playerId,
                      "text-white": currentPlayerId === score.playerId,
                    })}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100px",
                    }}
                  >
                    {score.player}
                    {currentPlayerId === score.playerId ? " (You)" : null}
                  </div>
                  {score.incomplete && (
                    <span className="badge badge-warning mr-2">Incomplete</span>
                  )}
                  {/* <span className="badge badge-info">Lvl {score.level}</span> */}
                </div>
                <Badge
                  variant="muted"
                  pill
                  className={cx("mt-3", { "d-none": collapsed })}
                >
                  <h6 className="m-0">
                    <FontAwesomeIcon icon={faClock} className="" />{" "}
                    <small>{convertSeconds(score.playTime)}</small>
                  </h6>
                </Badge>
              </ListGroup.Item>
            </OverlayTrigger>
          </>
        )
      })}
    </React.Fragment>
  ))

  return (
    <div className="overflow-auto" style={{ maxHeight: "calc(100vh - 60px)" }}>
      {!hideTitle && (
        <div>
          <Button
            className={cx("d-flex align-items-center justify-content-between", {
              // "justify-content-center": collapsed,
              // "justify-content-between ": !collapsed,
            })}
            size="lg"
            block
            variant="light"
            onClick={toggleScoreboard}
          >
            {collapsed && (
              <FontAwesomeIcon className="mr-2" icon={faChevronLeft} />
            )}
            <FontAwesomeIcon icon={faUsers} />
            {!collapsed && (
              <>
                <span>Leaderboard</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </>
            )}
          </Button>
        </div>
      )}
      <ListGroup as="ol" numbered>
        {renderScores?.length ? renderScores : null}
      </ListGroup>
    </div>
  )
}

function propsAreEqual(prevProps, nextProps) {
  if (prevProps.scores !== nextProps.scores) {
    return false
  }
  if (prevProps.collapsed !== nextProps.collapsed) {
    return false
  }
  return true
}

export default memo(Scoreboard, propsAreEqual)
