import {
  faBorderAll,
  faCheckCircle,
  faHistory,
  faInfoCircle,
  faMedal,
  faPlay,
  faQuestionCircle,
  faTimesCircle,
  faUser,
  faUserClock,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"
import dayjs from "dayjs"
import { initializeApp } from "firebase/app"
import { child, get, getDatabase, onValue, ref, set } from "firebase/database"
import React, { useEffect, useState } from "react"
import {
  Card as BSCard,
  Button,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap"
import Confetti from "react-confetti"
import { useStopwatch } from "react-timer-hook"
import { usePrevious } from "react-use"
import useWindowSize from "react-use/lib/useWindowSize"
import { v4 as uuidv4 } from "uuid"

import Card from "../components/Card"
import Layout from "../components/Layout"
import Scoreboard from "../components/Scoreboard"
import SEO from "../components/SEO"
import {
  loadPlayerId,
  loadPlayerName,
  savePlayerId,
  savePlayerName,
} from "../utils"
import * as styles from "./play.module.scss"

const wordList = require("./words_dictionary.json")

// const levels = [
//   { level: 1, range: [3, 4] },
//   { level: 2, range: [5, 6] },
//   { level: 3, range: [7, 8] },
//   // { level: 4, range: [9, 12] },
//   // { level: 5, range: [11, 16] },
//   // { level: 6, range: [12, 18] },
//   // { level: 7, range: [13, 20] },
//   // { level: 8, range: [14, 22] },
//   // { level: 9, range: [15, 15] },
//   // { level: 10, range: [15, 15] },
// ]

export const CARDS = [
  { 0: "a", cardNo: 1 },
  { 1: "b", 2: "c", cardNo: 2 },
  { 3: "d", 4: "e", cardNo: 3 },
  { 5: "f", 6: "g", cardNo: 4 },
  { 7: "h", 8: "i", cardNo: 5 },
  { 9: "j", 10: "k", cardNo: 6 },
  { 11: "l", 12: "m", cardNo: 7 },
  { 13: "n", 14: "o", cardNo: 8 },
  { 15: "p", 16: "q", cardNo: 9 },
  { 17: "r", 18: "s", cardNo: 10 },
  { 19: "t", 20: "u", cardNo: 11 },
  { 21: "v", 22: "w", cardNo: 12 },
  { 23: "x", 24: "y", cardNo: 13 },
  { 25: "z", cardNo: 14 },
]
export const cardColours = {
  0: "success", // A
  1: "danger", // B
  2: "info", // C
  3: "warning", // D
  4: "success", // E
  5: "danger", // F
  6: "info", // G
  7: "warning", // H
  8: "success", // I
  9: "danger", // J
  10: "info", // K
  11: "warning", // L
  12: "success", // M
  13: "danger", // N
  14: "info", // O
  15: "warning", // P
  16: "success", // Q
  17: "danger", // R
  18: "info", // S
  19: "warning", // T
  20: "success", // U
  21: "danger", // V
  22: "info", // W
  23: "warning", // X
  24: "success", // Y
  25: "danger", // Z
}
const card_collection = [...CARDS, ...CARDS, ...CARDS, ...CARDS]

const Play = ({ data, location }) => {
  const [levels, setLevels] = useState([
    { level: 1, range: [3, 4] },
    // { level: 2, range: [5, 6] },
    // { level: 3, range: [7, 8] },
    // { level: 4, range: [9, 12] },
    // { level: 5, range: [11, 16] },
    // { level: 6, range: [12, 18] },
    // { level: 7, range: [13, 20] },
    // { level: 8, range: [14, 22] },
    // { level: 9, range: [15, 15] },
    // { level: 10, range: [15, 15] },
  ])
  const [word, setWord] = useState([])
  const [playerName, setPlayerName] = useState(null)
  const [level, setLevel] = useState(levels[0])
  const [cardCollection, setCardCollection] = useState([])
  const [hand, setHand] = useState([])
  const [playArea, setPlayArea] = useState([])
  const [nominatedCards, setNominatedCards] = useState([])
  const [isValidWord, setIsValidWord] = useState(false)
  const [placementMode, setPlacementMode] = useState(false)
  const [isLevelComplete, setIsLevelComplete] = useState(false)
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [scoreboard, setScoreboard] = useState({})
  const [showMobScoreboard, setShowMobScoreboard] = useState(false)
  const [showScoreboard, setShowScoreboard] = useState(false)
  const [proMode, setProMode] = useState(true)
  const [showLevelPicker, setShowLevelPicker] = useState(false)

  const prevProMode = usePrevious(proMode)
  const { width, height } = useWindowSize()
  const isMobile = width <= 768
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: false })

  const firebaseConfig = {
    apiKey: "apiKey",
    authDomain: "alphabet-runner-game.firebaseapp.com",
    databaseURL:
      "https://alphabet-runner-game-default-rtdb.europe-west1.firebasedatabase.app/",
    storageBucket: "bucket.appspot.com",
  }

  const app = initializeApp(firebaseConfig)
  const database = getDatabase(app)

  // pick 7 random cards assign to "hand" state
  // pick 3-4 random remaining cards and store in "play area"
  // store "nominated" card
  // handle "submission" of word and check if exists
  // store "level" and level config

  function checkWord(word) {
    if (word && word.length >= level.range[0]) {
      const foundWord = wordList[word?.toLowerCase()]
      if (foundWord) {
        return true
      }
    } else return false
  }

  function dealCards(collection, noOfCards, isPlayArea) {
    const cards = []
    const selectedCards = isPlayArea ? playArea : hand
    const newCollection = [...collection]
    ;[...Array(noOfCards)].forEach((_, i) => {
      let card = getRandomCard(newCollection)
      let ix = cards.findIndex(c => c.index === card.index)
      let sx = selectedCards.findIndex(c => c.index === card.index)
      while (ix !== -1 || sx !== -1) {
        // loop until unique card is found
        card = getRandomCard(newCollection)
        ix = cards.findIndex(c => c.index === card.index)
        sx = selectedCards.findIndex(c => c.index === card.index)
        if (ix === -1 && sx === -1) {
          break
        }
      }
      if (ix === -1 && sx === -1) {
        let cx = newCollection.findIndex(c => c.index === card.index)
        newCollection.splice(cx, 1)
        cards.push(card)
      }
    })
    if (isPlayArea) {
      setPlayArea(cards)
    } else {
      setHand(cards)
    }
    return newCollection
  }

  function nominateCard(card) {
    const isAlreadySelected = nominatedCards.find(c => c.index === card.index)
    if (!isAlreadySelected) {
      if (!isRunning) {
        setStartTime(dayjs().format())
        start()
      }
      setWord([])
      setNominatedCards([card])
    }
  }

  function getRandomCard(collection) {
    const randomIndex = Math.floor(Math.random() * collection.length)
    return collection[randomIndex]
  }

  function shuffleCards() {
    const formatted = card_collection.map((card, i) => {
      return { ...card, index: i }
    })
    return shuffle(formatted)
  }

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--
      // And swap it with the current element.
      ;[array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ]
    }
    return array
  }

  function handleLetterSelection(letter) {
    const duplicatedLetterIndex = word.findIndex(
      l => l.letter === letter.letter && l.index === letter.index
    )
    if (duplicatedLetterIndex === -1) {
      setWord(prev => prev.concat(letter))
    } else {
      if (word[word.length - 1].letter === letter.letter) {
        if (word.length === 1) {
          setWord([])
        } else {
          setWord(word.slice(0, -1))
        }
      }
    }
  }

  function handleDealCards() {
    setNominatedCards([])
    setWord([])
    setPlacementMode(false)
    setIsValidWord(false)
    // reset the card collection
    let cards = shuffleCards()
    cards = dealCards(cards, level.range[1] === 15 ? 22 : level.range[1], true)
    cards = dealCards(cards, 7)
    setCardCollection(cards)
    reset()
    pause()
    setStartTime(null)
    setEndTime(null)
  }

  async function handlePlaceCard(index) {
    const currentPlayArea = [...playArea]
    const newPlacedCard = { ...nominatedCards[0] }
    currentPlayArea[index] = newPlacedCard
    setPlayArea(currentPlayArea)
    setNominatedCards([])
    const newHand = hand.filter(c => c.index !== newPlacedCard.index)

    if (!proMode) {
      if (!newHand.length) {
        pause()
        setEndTime(dayjs().format())
        // when there are no cards left
        await submitScore()
        setTimeout(() => {
          handleNextLevel()
        }, 2000)
        setIsLevelComplete(true)
      } else {
        setHand(newHand)
        setPlacementMode(false)
        setIsValidWord(false)
      }
    } else {
      if (!newHand.length) {
        if (cardCollection.length) {
          // while there are more cards in the deck to play with
          const nextDealCards =
            cardCollection.length < 7 ? cardCollection.length : 7
          let cards = dealCards(cardCollection, nextDealCards)
          setCardCollection(cards)
          setPlacementMode(false)
          // setHand(newHand)
          setIsValidWord(false)
        } else {
          // when there are no cards left
          pause()
          setEndTime(dayjs().format())
          await submitScore()
          setTimeout(() => {
            setShowLevelPicker(true)
            // handleNextLevel()
          }, 2000)
          setIsLevelComplete(true)
        }
      } else {
        setHand(newHand)
        setPlacementMode(false)
        setIsValidWord(false)
      }
    }
  }

  const handleNextLevel = levl => {
    const nextLevel = levl || levels[level.level + 1 - 1]
    setLevel(nextLevel)
    setShowLevelPicker(false)
  }

  async function submitScore(saveToLocalStorage) {
    const playerId = loadPlayerId()
    const scorePayload = {
      player: playerName,
      level: level.level,
      start: `${startTime}`,
      end: `${dayjs().format()}`,
      proMode: proMode,
      time: {
        days,
        hours,
        minutes,
        seconds,
      },
    }
    if (saveToLocalStorage) {
      localStorage.setItem("score", JSON.stringify(scorePayload))
    } else {
      return set(
        ref(database, `/level-${level.level}-${playerId}`),
        scorePayload
      ).then(response => {
        console.log("score saved", scorePayload)
        return response
      })
    }
  }

  useEffect(() => {
    if (!isMobile) {
      setLevels([
        { level: 1, range: [3, 4] },
        // { level: 2, range: [5, 6] },
        // { level: 3, range: [7, 8] },
        // { level: 4, range: [9, 12] },
        // { level: 5, range: [10, 14] },
        // { level: 6, range: [11, 16] },
        // { level: 7, range: [12, 18] },
        // { level: 8, range: [13, 20] },
        // { level: 9, range: [14, 22] },
        // { level: 10, range: [15, 22] },
      ])
    }
  }, [isMobile])

  useEffect(() => {
    handleDealCards()
  }, [level])

  useEffect(() => {
    if (!nominatedCards.length) {
      setWord([])
    }
  }, [nominatedCards])

  useEffect(() => {
    // on every letter change while word lenth
    // is within level range
    if (
      word.length &&
      word.length >= level.range[0] &&
      word.length <= level.range[1]
    ) {
      let isValidWord = false
      let lettersFromNominatedCard
      const _word = word.map(w => w.letter.split("_")[0]).join("")
      // check if nominated word is used
      if (nominatedCards.length) {
        lettersFromNominatedCard = word.filter(
          l => l.index === nominatedCards[0]?.index
        )
      }
      if (lettersFromNominatedCard.length) {
        isValidWord = checkWord(_word)
        if (isValidWord) {
          setIsValidWord(true)
          setPlacementMode(true)
        } else {
          if (word.length === level.range[1]) {
            setTimeout(() => {
              setIsValidWord(false)
              setWord([])
            }, 2000)
          }
        }
      }
    }
  }, [word])

  useEffect(() => {
    pause()
    document.body.classList.add("bg-dark-purple")
    const existingPlayerId = loadPlayerId()
    if (!existingPlayerId) {
      setPlayerName(loadPlayerName())
      const newPlayerId = uuidv4().split("-")[4]
      savePlayerId(newPlayerId)
    } else {
      savePlayerId(loadPlayerId())
      setPlayerName(loadPlayerName())
    }
    // const cards = shuffleCards()
    // setCardCollection(cards)
    const starCountRef = ref(database, "/")
    onValue(starCountRef, snapshot => {
      const data = snapshot.val()
      console.log({ data })
      setScoreboard(data)
    })
  }, [])

  useEffect(() => {
    if (showMobScoreboard) {
      setShowScoreboard(false)
    }
    if (showScoreboard) {
      setShowMobScoreboard(false)
    }
  }, [showMobScoreboard, showScoreboard])

  useEffect(() => {
    if (proMode !== prevProMode) {
      handleDealCards()
    }
  }, [proMode])

  useEffect(() => {
    const handleBeforeUnload = event => {
      event.preventDefault()
    }

    window.addEventListener("beforeunload", handleBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    submitScore(true)
  }, [seconds])

  function renderCards(cards, selectable, isHand) {
    return cards.map((card, i) => {
      const isNominated = nominatedCards.find(c => c === card)
      if (card) {
        return (
          <div
            className={cx(styles.cardBasis)}
            style={{
              flexBasis: level.level > 3 ? "6.5%" : null,
            }}
            key={card?.index}
          >
            <Card
              index={i}
              card={card}
              disabled={!isNominated && !selectable}
              onClick={isHand ? () => nominateCard(card) : null}
              handleLetterSelection={handleLetterSelection}
              word={word}
              isNominated={nominatedCards.find(c => c.index === card.index)}
              placementMode={!isHand && placementMode}
              handlePlaceCard={handlePlaceCard}
              isPlayArea={isHand}
              // flexBasis={isHand ? "11%" : null}
            />
          </div>
        )
      }
    })
  }

  const renderTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      Timer Started!
    </Tooltip>
  )

  function renderInstructions() {
    const madeWord = word
      .map(w => w.letter.split("_")[0])
      .join("")
      .toUpperCase()
    if (!nominatedCards.length) {
      return "Click a card to nominate as the playing card..."
    }
    if (
      word.length >= level.range[0] &&
      !word.filter(l => l.index === nominatedCards[0]?.index).length
    ) {
      return <>You must use your nominated card!</>
    }
    if (isValidWord) {
      return (
        <>
          <strong>&#127881; {madeWord}</strong> is a word! You can now place
          your card...
        </>
      )
    }
    if (!isValidWord && word.length === level.range[1]) {
      return (
        <>
          <strong>&#128577; {madeWord}</strong> is not a word!
        </>
      )
    }
    return (
      <>
        Make a word between {level.range[0]} -{" "}
        {level.range[0] === 15 ? 45 : level.range[1]} letters
      </>
    )
  }

  function renderGameMenu() {
    return (
      <div
        className={styles.gameMenu}
        style={{ display: playerName ? "none" : undefined }}
      >
        <BSCard bg="light" style={{ width: "25rem" }}>
          {/* <BSCard.Header>Header</BSCard.Header> */}
          <BSCard.Body>
            <BSCard.Title>
              <h2>Welcome!</h2>
              <p className="text-muted">
                Thanks for playing The MaxxRas WORD GAME
              </p>
            </BSCard.Title>
            <Form
              onSubmit={e => {
                e.preventDefault()
                if (!e.target.elements.playerName.value?.length) {
                  setPlayerName("Anonymous")
                  savePlayerName("Anonymous")
                } else {
                  setPlayerName(e.target.elements.playerName.value)
                  savePlayerName(e.target.elements.playerName.value)
                }
              }}
            >
              <Form.Group className="mb-3" controlId="playerName">
                <Form.Label>Enter a name to continue...</Form.Label>
                <Form.Control size="lg" type="text" placeholder="Anonymous" />
              </Form.Group>
              <Button block size="lg" variant="primary" type="submit">
                Let's Play!
              </Button>
            </Form>
            {/* <BSCard.Text>
          Some quick example text to build on the card title and make up
          the bulk of the card's content.
        </BSCard.Text> */}
          </BSCard.Body>
        </BSCard>
      </div>
    )
  }

  return (
    <Layout fluid noFooter location={location}>
      <SEO />
      <div
        className="row overflow-hidden"
        style={{
          height: `calc(100vh - 60px)`,
        }}
      >
        <div
          className="col"
          style={{
            height: `100%`,
            overflowY: "scroll",
          }}
        >
          {renderGameMenu()}
          {/* <Confetti
            run={isLevelComplete}
            style={{ zIndex: 10 }}
            recycle={false}
            width={width - 50}
            height={height - 60}
            numberOfPieces={500}
            initialVelocityY={50}
            gravity={0.5}
            tweenDuration={5000}
            // onConfettiComplete={() => handleNextLevel()}
          /> */}
          <div className="row">
            <div className="col-7 p-3">
              <div className="d-flex align-items-center">
                <h2
                  className={cx(
                    styles.playerTitle,
                    "text-light mr-4 d-none d-md-block"
                  )}
                >
                  Hi &#128075;, {playerName}{" "}
                </h2>
                <DropdownButton
                  variant={"info"}
                  size="sm"
                  title={`Level ${level?.level}`}
                >
                  <Dropdown.Item
                    className="px-3"
                    // onClick={() => setProMode(prev => !prev)}
                  >
                    <FontAwesomeIcon
                      icon={faMedal}
                      className="mr-2 text-primary"
                    />
                    Solo Mode{" "}
                    {/* <span
                      className={cx("badge", {
                        "badge-success": proMode,
                        "badge-danger": !proMode,
                      })}
                    >
                      {proMode ? "On" : "Off"}
                    </span> */}
                    <div>
                      <small className="text-muted">
                        {proMode
                          ? "Play through the deck until all cards are used"
                          : "Play with 7 cards per level"}
                      </small>
                    </div>
                  </Dropdown.Item>
                  {levels.map(l => (
                    <Dropdown.Item
                      key={l?.level}
                      eventKey={l?.level}
                      className="px-3"
                      active={l?.level === level?.level}
                      onClick={() => handleNextLevel(l)}
                    >
                      Level {l.level}{" "}
                      <small className="text-muted">
                        ({l?.range[0]} - {l.range[0] === 15 ? 45 : l.range[1]}{" "}
                        Letter Word)
                      </small>
                    </Dropdown.Item>
                  ))}
                  {/* {isMobile && (
                    <Dropdown.Item disabled className="px-3">
                      <small>
                        <FontAwesomeIcon icon={faInfoCircle} /> 7 more levels on
                        desktop
                      </small>
                    </Dropdown.Item>
                  )} */}
                </DropdownButton>
                <Button
                  className="ml-1 d-block d-md-none"
                  size="sm"
                  type="button"
                  onClick={() => setShowMobScoreboard(true)}
                >
                  Leaderboard
                </Button>
              </div>
            </div>
            <div
              className={cx(
                styles.timer,
                "col p-3 text-light h3 d-flex align-items-center justify-content-end"
              )}
            >
              <FontAwesomeIcon icon={faUserClock} className="mr-2" />

              <OverlayTrigger
                trigger="click"
                placement={"bottom"}
                show={
                  !showMobScoreboard &&
                  !showLevelPicker &&
                  startTime &&
                  seconds < 10
                }
                overlay={renderTooltip}
              >
                <div
                  className={cx({ [`${styles.gameTimerBlinker}`]: !startTime })}
                >
                  {hours > 0 && <span>{hours}:</span>}
                  {/* {minutes > 0 && <span>{minutes}:</span>} */}
                  <span>{minutes < 10 ? `0${minutes}` : minutes}:</span>
                  <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
                </div>
              </OverlayTrigger>

              <Button
                variant="danger"
                disabled={!isRunning}
                className="d-none d-mb-block ml-3"
                onClick={() => handleDealCards()}
              >
                <FontAwesomeIcon size="lg" icon={faHistory} />
              </Button>

              <Button
                variant="danger"
                className="d-block d-mb-none ml-3"
                size="sm"
                disabled={!isRunning}
                onClick={() => handleDealCards()}
              >
                <FontAwesomeIcon size="lg" icon={faHistory} />
              </Button>
            </div>
          </div>
          <div className="row">
            <div
              className={cx("col text-center mb-5", {
                "text-muted": !word.length,
                "text-success": isValidWord,
                "text-danger": !isValidWord && word.length === level.range[1],
                "text-warning": !isValidWord && word.length > 0,
              })}
            >
              <h1 className={styles.madeWord}>
                <FontAwesomeIcon
                  className="mr-3"
                  icon={
                    word.length
                      ? isValidWord
                        ? faCheckCircle
                        : faTimesCircle
                      : faQuestionCircle
                  }
                />

                {word.length
                  ? word
                      .map(w => w.letter.split("_")[0])
                      .join("")
                      .toUpperCase()
                  : `${level.range[0]} - ${
                      level.range[0] === 15 ? 45 : level.range[1]
                    } Letter Word`}
              </h1>
              <h6 className="m-0">{renderInstructions()}</h6>
              {/* <button
                disabled={word.length <= level.range[0]}
                className="btn btn-sm btn-primary mt-3"
              >
                Check Word
                <FontAwesomeIcon className="ml-3" icon={faPlay} />
              </button> */}
            </div>
          </div>
          <div className="row">
            <div className="col text-info">
              <h6 className="mb-3">
                <FontAwesomeIcon icon={faBorderAll} className="mr-2" />
                PLAY AREA
              </h6>
              <div id="hand-container" className={cx(styles.cardContainer)}>
                {renderCards(playArea, nominatedCards.length ? true : false)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col text-warning">
              <h6 className="mb-3">
                <FontAwesomeIcon icon={faUser} className="mr-2" />
                PLAYER 1{" "}
                {proMode ? `(${cardCollection.length} cards left)` : null}
              </h6>
              <div id="hand-container" className={cx(styles.cardContainer)}>
                {renderCards(
                  hand,
                  !nominatedCards.length && !placementMode,
                  true
                )}
              </div>
            </div>
            {/* <div className="col-6">
          {cardCollection.length} Cards
          <div id="hand-container" className={cx(styles.cardContainer)}>
            {renderCards(cardCollection)}
          </div>
        </div> */}
          </div>
        </div>
        <div
          className="col flex-grow-0 bg-light p-0 d-none d-md-flex"
          style={
            {
              // minWidth: showScoreboard ? "280px" : "auto",
              // display: showScoreboard ? "flex" : "none",
            }
          }
        >
          <Scoreboard
            scores={scoreboard}
            collapsed={!showScoreboard}
            toggleScoreboard={() => setShowScoreboard(prev => !prev)}
          />
          {/* <Button variant="primary" onClick={handleShow}>
            Launch demo modal
          </Button> */}

          <Modal
            show={showMobScoreboard}
            onHide={() => setShowMobScoreboard(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Leaderboard</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
              <Scoreboard hideTitle scores={scoreboard} />
            </Modal.Body>
          </Modal>
          {showLevelPicker && (
            <Modal size="sm" show onHide={() => setShowLevelPicker(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Pick next level</Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-0">
                <div className="alert alert-success">
                  Well Done! Level Completed!
                </div>
                {levels.map(l => (
                  <div
                    key={l?.level}
                    eventKey={l?.level}
                    className={cx("px-3 py-2", styles.levelItem)}
                    onClick={() => handleNextLevel(l)}
                  >
                    Level {l.level}{" "}
                    <small className="text-muted">
                      ({l?.range[0]} - {l.range[0] === 15 ? 45 : l.range[1]}{" "}
                      Letter Word)
                      {l.level === level.level && (
                        <span className="ml-3 badge badge-success">
                          Completed!
                        </span>
                      )}
                    </small>
                  </div>
                ))}
              </Modal.Body>
            </Modal>
          )}
        </div>
      </div>
    </Layout>
  )
}
export default Play
